import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, collection, setDoc, getDoc } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { initializeAuth, RecaptchaVerifier } from "firebase/auth";



export const firebaseConfig = {
  // Your web app's Firebase configuration
  apiKey: "AIzaSyCWTRRi-VsC_Nc4TLGzLLrnfrHIhy-CvLM",
  authDomain: "wedding-f79a0.firebaseapp.com",
  projectId: "wedding-f79a0",
  storageBucket: "wedding-f79a0.appspot.com",
  messagingSenderId: "699740856430",
  appId: "1:699740856430:web:1b605fd766e0b7c590867f",
  measurementId: "G-0HX59VLX6R"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);



export {
  auth,
  firestore,
  storage,
  doc,
  collection,
  setDoc,
  getDoc,
  RecaptchaVerifier,

};
