import { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  TextField,
  CircularProgress,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Divider,
  IconButton,
  Box,
} from "@mui/material";
import { Alert } from "@mui/material";
import axios from "axios";
import moment from "moment";
import {
  Search as SearchIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";

const AddNewUser = () => {
  const [users, setUsers] = useState([]);
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState("");
  const [deleteUserEmail, setDeleteUserEmail] = useState("");
  const token = localStorage.getItem("firebaseToken");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [deleteEmailValid, setDeleteEmailValid] = useState(false);
  const emailRegex = /^\S+@\S+\.\S+$/;
  const [deleteUserObj, setDeleteUserObj] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    loadAllUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const filtered = users.filter((user) =>
      user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredUsers(filtered);
  }, [users, searchTerm]);

  const loadAllUsers = async () => {
    try {
      const response = await axios.get(
        "https://us-central1-wedding-f79a0.cloudfunctions.net/loadAllUsers",
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = response.data;
      const updatedUsers = data.users.map((user) => {
        if (user.lastLoggedIn) {
          user.lastLoggedInFormatted = moment(user.lastLoggedIn).format(
            "YYYY-MM-DD HH:mm:ss"
          );
        } else {
          user.lastLoggedInFormatted = "Never";
        }
        return user;
      });
      setUsers(updatedUsers);
    } catch (error) {
      console.error("Error loading users:", error);
    } finally {
      setLoading(false);
    }
  };

  const validateEmail = () => {
    setIsEmailValid(emailRegex.test(email));
  };

  const addUser = async () => {
    if (users.some((user) => user.email === email)) {
      setSnackbarMessage("Email already exists");
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        "https://us-central1-wedding-f79a0.cloudfunctions.net/addNewUser",
        {
          email: email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = response.data;
      console.log(data);
      setSnackbarSeverity("success");
      setSnackbarMessage("User has been added");
      setSnackbarOpen(true);
      setEmail("");
      loadAllUsers();
    } catch (error) {
      console.error("Error adding user:", error);
    } finally {
      setLoading(false);
    }
  };

  const deleteUser = async (id) => {
    setLoading(true);
    setDeleteDialogOpen(false);
    try {
      const response = await axios.post(
        "https://us-central1-wedding-f79a0.cloudfunctions.net/deleteUser",
        { userId: id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.success === false) {
        setSnackbarMessage(response.data.message);
        setSnackbarSeverity("error");
      } else {
        setSnackbarMessage("User has been deleted");
        setSnackbarSeverity("success");
      }
      setSnackbarOpen(true);
      setDeleteDialogOpen(false);
      setDeleteUserEmail("");
      loadAllUsers();
    } catch (error) {
      console.error("Error deleting user:", error);
      setSnackbarMessage("Error deleting user");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteUser = (id) => {
    const userToDelete = users.find((user) => user.uid === id);
    if (userToDelete) {
      setDeleteUserObj(userToDelete);
      setDeleteUserEmail("");
      setDeleteDialogOpen(true);
    } else {
      console.error(`User with ID ${id} not found`);
      setSnackbarMessage("Error finding user for deletion");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleConfirmDelete = () => {
    if (
      deleteUserObj &&
      emailRegex.test(deleteUserEmail) &&
      deleteUserEmail === deleteUserObj.email
    ) {
      deleteUser(deleteUserObj.uid);
    } else {
      console.error("Invalid email or user object");
      setSnackbarMessage("Invalid email or user not found");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
    setDeleteUserEmail("");
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleDeleteUserMobile = (id) => {
    const userToDelete = users.find((user) => user.uid === id);
    if (userToDelete) {
      setDeleteUserObj(userToDelete);
      setDeleteUserEmail("");
      setDeleteDialogOpen(true);
    } else {
      console.error(`User with ID ${id} not found`);
      setSnackbarMessage("Error finding user for deletion");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const columns = [
    { field: "email", headerName: "Email", flex: 1 },
    {
      field: "lastLoggedInFormatted",
      headerName: "Last Logged In",
      flex: 1,
    },
    {
      field: "delete",
      headerName: "Delete",
      flex: 0.2,
      sortable: false,
      renderCell: (params) => (
        <IconButton
          onClick={() => handleDeleteUserMobile(params.row.uid)}
          size="small"
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <div style={{ padding: "16px" }}>
      <Typography variant="h6">Add New User</Typography>
      <Divider sx={{ margin: "16px 0" }} />
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
        <TextField
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onBlur={validateEmail}
          label="Email"
          variant="outlined"
          style={{ marginRight: "16px" }}
          error={!isEmailValid && email.length > 0}
          helperText={!isEmailValid && email.length > 0 ? "Invalid email" : ""}
          fullWidth
        />
        <Button
          variant="contained"
          color="primary"
          onClick={addUser}
          disabled={!isEmailValid}
        >
          Add User
        </Button>
      </Box>
      <TextField
        value={searchTerm}
        onChange={handleSearchChange}
        label="Search"
        variant="outlined"
        style={{ marginBottom: "16px" }}
        fullWidth
      />
      {loading ? (
        <CircularProgress style={{ margin: "auto" }} />
      ) : (
        <DataGrid
          rows={filteredUsers}
          columns={columns}
          pageSize={5}
          getRowId={(row) => row.uid}
        />
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          severity={snackbarSeverity}
          onClose={() => setSnackbarOpen(false)}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Dialog open={deleteDialogOpen} onClose={handleCancelDelete}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to delete this user? Please re-enter their
            email to confirm.
          </Typography>
          <TextField
            value={deleteUserEmail}
            onChange={(e) => {
              setDeleteUserEmail(e.target.value);
              setDeleteEmailValid(emailRegex.test(e.target.value));
            }}
            onBlur={() => setDeleteEmailValid(emailRegex.test(deleteUserEmail))}
            label="Email"
            variant="outlined"
            style={{ marginTop: "1em" }}
            error={!deleteEmailValid && deleteUserEmail.length > 0}
            helperText={
              !deleteEmailValid && deleteUserEmail.length > 0
                ? "Invalid email"
                : ""
            }
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            color="secondary"
            autoFocus
            disabled={!deleteEmailValid}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddNewUser;
