import React, { useEffect, useState } from "react";
import { ref, onValue, push, set, getDatabase, off } from "firebase/database";
import { getAuth } from "firebase/auth";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Button,
  TextField,
  Typography,
  Divider,
} from "@mui/material";

const Comments = ({ media }) => {
  const [comments, setComments] = useState([]);
  const [loadedComments, setLoadedComments] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [commentInput, setCommentInput] = useState("");
  const [replyInput, setReplyInput] = useState("");
  const [selectedComment, setSelectedComment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const database = getDatabase();
  const auth = getAuth();

  useEffect(() => {
    const commentsRef = ref(database, "comments/" + media.id);
    onValue(commentsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const commentsArray = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));
        setComments(commentsArray);
      } else {
        setComments([]);
      }
      setLoading(false);
    });

    return () => {
      off(commentsRef);
    };
  }, [media.id, database]);

  useEffect(() => {
    if (comments.length > 0) {
      const sortedComments = [...comments].sort(
        (a, b) => (b.replies?.length || 0) - (a.replies?.length || 0)
      );
      const initialComments = sortedComments.slice(0, 3);
      setLoadedComments(initialComments);
      setShowMore(sortedComments.length > 3);
    }
  }, [comments]);

  const handleLoadMore = () => {
    const currentLength = loadedComments.length;
    const additionalComments = comments.slice(currentLength, currentLength + 3);
    setLoadedComments([...loadedComments, ...additionalComments]);
    setShowMore(currentLength + 3 < comments.length);
  };

  const handleCommentSubmit = () => {
    if (commentInput.trim() !== "") {
      const newComment = {
        comment: commentInput.trim(),
        email: auth.currentUser.email,
        createdAt: new Date().toISOString(),
        replies: [],
      };

      const commentsRef = ref(database, "comments/" + media.id);
      const newCommentRef = push(commentsRef);
      const newCommentId = newCommentRef.key;

      set(newCommentRef, newComment)
        .then(() => {
          setComments([...comments, { id: newCommentId, ...newComment }]);
          setCommentInput("");
        })
        .catch((error) => {
          setError("Failed to submit comment. Please try again.");
        });
    }
  };

  const handleReplySubmit = () => {
    if (replyInput.trim() !== "" && selectedComment) {
      const newReply = {
        reply: replyInput.trim(),
        email: auth.currentUser.email,
        createdAt: new Date().toISOString(),
      };

      const repliesRef = ref(
        database,
        `comments/${media.id}/${selectedComment}/replies`
      );
      const newReplyRef = push(repliesRef);
      const newReplyId = newReplyRef.key;

      set(newReplyRef, newReply)
        .then(() => {
          const updatedComments = comments.map((comment) => {
            if (comment.id === selectedComment) {
              return {
                ...comment,
                replies: [
                  ...(comment.replies || []),
                  { id: newReplyId, ...newReply },
                ],
              };
            }
            return comment;
          });
          setComments(updatedComments);
          setReplyInput("");
          setSelectedComment(null);
        })
        .catch((error) => {
          setError("Failed to submit reply. Please try again.");
        });
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      <List>
        {loading ? (
          <ListItem>
            <ListItemText primary="Loading comments..." />
          </ListItem>
        ) : comments.length === 0 ? (
          <ListItem>
            <ListItemText primary="No comments yet." />
          </ListItem>
        ) : (
          loadedComments.map((comment) => (
            <>
              <ListItem key={comment.id}>
                <ListItemText
                  primary={comment.comment}
                  secondary={`Commented by: ${comment.email}`}
                />
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => setSelectedComment(comment.id)}
                >
                  Reply
                </Button>
              </ListItem>
              {Array.isArray(comment.replies) &&
                comment.replies.map((reply) => (
                  <ListItem key={reply.id} style={{ marginLeft: "20px" }}>
                    <ListItemText
                      primary={reply.reply}
                      secondary={`Replied by: ${reply.email}`}
                    />
                  </ListItem>
                ))}
              <Divider variant="inset" component="li" />
            </>
          ))
        )}
      </List>
      {showMore && (
        <Button
          variant="outlined"
          size="small"
          onClick={handleLoadMore}
          disabled={loading}
        >
          Load More
        </Button>
      )}
      <TextField
        label="Add a comment"
        value={commentInput}
        onChange={(e) => setCommentInput(e.target.value)}
        fullWidth
        multiline
        rows={2}
        margin="normal"
        variant="outlined"
      />
      <Button variant="contained" onClick={handleCommentSubmit}>
        Comment
      </Button>
      {selectedComment && (
        <>
          <TextField
            label="Add a reply"
            value={replyInput}
            onChange={(e) => setReplyInput(e.target.value)}
            fullWidth
            multiline
            rows={2}
            margin="normal"
            variant="outlined"
          />
          <Button variant="contained" onClick={handleReplySubmit}>
            Reply
          </Button>
        </>
      )}
      {error && <Typography color="error">{error}</Typography>}
    </Box>
  );
};

export default Comments;
