import React, { useState, useEffect } from "react";
import { collection, doc, setDoc, getDoc, updateDoc } from "firebase/firestore";
import { firestore, auth } from "../../firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  FormControl,
  Box,
  Divider,
  Snackbar,
  Alert,
} from "@mui/material";

export default function RsvpForm() {
  const [user] = useAuthState(auth);
  const [rsvp, setRsvp] = useState({
    cocktail: "",
    wedding: "",
    guests: [{ name: "", age: "", relationship: "" }],
  });
  const [modifiable, setModifiable] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [validForm, setValidForm] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    const fetchRsvp = async () => {
      if (!user) return;

      const userDoc = doc(firestore, "rsvp", user.uid);
      const docSnap = await getDoc(userDoc);

      if (docSnap.exists()) {
        setRsvp(docSnap.data());
        setSubmitted(true);
        setModifiable(false);
        validateForm(docSnap.data());
      } else {
        setModifiable(true);
      }
    };

    fetchRsvp();
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user || !validForm) return;

    const userDoc = doc(firestore, "rsvp", user.uid);
    const docSnap = await getDoc(userDoc);

    if (docSnap.exists()) {
      await updateDoc(userDoc, rsvp);
    } else {
      await setDoc(userDoc, { ...rsvp, userId: user.uid });
    }

    setSubmitted(true);
    setModifiable(false);
    setOpenSnackbar(true);
  };

  const handleRsvpChange = (event) => {
    if (modifiable) {
      const newRsvp = { ...rsvp, [event.target.name]: event.target.value };
      setRsvp(newRsvp);
      validateForm(newRsvp);
    }
  };

  const handleGuestChange = (event, index) => {
    if (modifiable) {
      const newGuests = [...rsvp.guests];
      newGuests[index][event.target.name] = event.target.value;
      // Prevent changing "Relationship" for the first guest
      if (index === 0) {
        newGuests[index].relationship = "Self";
      }
      const newRsvp = { ...rsvp, guests: newGuests };
      setRsvp(newRsvp);
      validateForm(newRsvp);
    }
  };

  const validateForm = (rsvp) => {
    let isValid = rsvp.cocktail !== "" && rsvp.wedding !== "";
    isValid =
      isValid &&
      rsvp.guests.every(
        (guest) =>
          guest.name !== "" && guest.age !== "" && guest.relationship !== ""
      );
    setValidForm(isValid);
  };

  const addGuest = () => {
    if (modifiable) {
      setRsvp({
        ...rsvp,
        guests: [...rsvp.guests, { name: "", age: "", relationship: "" }],
      });
    }
  };

  const removeGuest = (index) => {
    if (modifiable) {
      const newGuests = [...rsvp.guests];
      newGuests.splice(index, 1);
      setRsvp({ ...rsvp, guests: newGuests });
    }
  };

  const handleModify = () => {
    setModifiable(true);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px 0 0",
      }}
    >
      <h2 style={{ fontFamily: "Zeyada", fontSize: "40px", color: "#557054", fontWeight:'bold' }}>
        {submitted ? "Your RSVP" : "Submit your RSVP"}
      </h2>

      <Button onClick={handleModify} disabled={!submitted || modifiable}>
        Modify
      </Button>

      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "16px",
          }}
        >
          <FormControl component="fieldset" required>
            <FormLabel component="legend">Cocktail Party</FormLabel>
            <RadioGroup
              row
              name="cocktail"
              value={rsvp.cocktail}
              onChange={handleRsvpChange}
            >
              <FormControlLabel
                value="yes"
                control={<Radio />}
                label="Yes"
                disabled={!modifiable}
              />
              <FormControlLabel
                value="no"
                control={<Radio />}
                label="No"
                disabled={!modifiable}
              />
            </RadioGroup>
          </FormControl>

          <FormControl
            component="fieldset"
            required
            sx={{ marginLeft: "16px" }}
          >
            <FormLabel component="legend">Wedding</FormLabel>
            <RadioGroup
              row
              name="wedding"
              value={rsvp.wedding}
              onChange={handleRsvpChange}
            >
              <FormControlLabel
                value="yes"
                control={<Radio />}
                label="Yes"
                disabled={!modifiable}
              />
              <FormControlLabel
                value="no"
                control={<Radio />}
                label="No"
                disabled={!modifiable}
              />
            </RadioGroup>
          </FormControl>
        </Box>

        <h3>Guests Information:</h3>
        {rsvp.guests.map((guest, index) => (
          <Box key={index} sx={{ marginBottom: "16px" }}>
            <Divider sx={{ marginBottom: "16px" }} />
            <TextField
              label="Name"
              name="name"
              value={guest.name}
              onChange={(e) => handleGuestChange(e, index)}
              required
              disabled={!modifiable}
              sx={{ marginLeft: "10px", marginBottom: "10px" }}
            />
            <TextField
              label="Age"
              name="age"
              value={guest.age}
              onChange={(e) => handleGuestChange(e, index)}
              type="number"
              required
              disabled={!modifiable}
              sx={{ marginLeft: "10px", marginBottom: "10px" }}
            />
            <TextField
              label="Relationship"
              name="relationship"
              value={guest.relationship}
              onChange={(e) => handleGuestChange(e, index)}
              required
              disabled={!modifiable}
              sx={{ marginLeft: "10px", marginBottom: "10px" }}
            />
            {modifiable && index > 0 && (
              <Button onClick={() => removeGuest(index)}>Remove</Button>
            )}
          </Box>
        ))}
        {modifiable && <Button onClick={addGuest}>Add Guest</Button>}
        {modifiable && (
          <Button type="submit" disabled={!validForm}>
            Submit
          </Button>
        )}
      </form>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          RSVP submitted successfully
        </Alert>
      </Snackbar>
    </Box>
  );
}
