import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  doc,
  setDoc,
  deleteDoc,
  getDoc,
} from "firebase/firestore";
import { firestore } from "../../firebaseConfig";
import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Divider,
  Button,
  TextField,
} from "@mui/material";
import { Delete as DeleteIcon, Send as SendIcon } from "@mui/icons-material";
import { styled } from "@mui/system";
import moment from "moment";

const StyledListItem = styled(ListItem)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  backgroundColor: ({ viewed }) => (viewed ? "transparent" : "#e8f0fe"),
  "&:hover": {
    backgroundColor: "#f5faff",
  },
}));

const UserMailDataGrid = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [replyMessage, setReplyMessage] = useState("");

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    const contactsCollection = collection(firestore, "contacts");
    const contactsSnapshot = await getDocs(contactsCollection);
    const usersList = contactsSnapshot.docs.reduce((users, doc) => {
      const messageData = doc.data();
      const datetime = messageData.timestamp
        ? moment(messageData.timestamp.toDate()).format("YYYY-MM-DD HH:mm:ss")
        : "";
      const message = {
        id: doc.id,
        ...messageData,
        datetime: datetime,
      };
      const user = users.find((u) => u.name === message.name);
      if (user) {
        user.messages.push(message);
      } else {
        users.push({
          name: message.name,
          email: message.email,
          messages: [message],
        });
      }
      return users;
    }, []);
    const sortedUsers = usersList.sort((a, b) =>
      moment(b.messages[0].datetime).diff(a.messages[0].datetime)
    );
    setUsers(sortedUsers);
    setLoading(false);
  };

  const handleSelectUser = (user) => {
    setSelectedUser(user);
    setMessages(user.messages);
  };

  const handleReplyToUser = async () => {
    // Implement reply functionality
  };

  const handleDeleteMessage = async (id) => {
    // Implement delete functionality
  };

  const handleChangeReplyMessage = (event) => {
    setReplyMessage(event.target.value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      {/* Header */}
      <Box
        sx={{
          padding: 2,
          borderBottom: "1px solid #ddd",
        }}
      >
        <Typography variant="h6">User Mail</Typography>
      </Box>

      {/* Sidebar */}
      <Box
        sx={{
          flex: 1,
          overflow: "auto",
          padding: 2,
        }}
      >
        <Typography variant="h6">Users</Typography>
        {users.length > 0 ? (
          <List sx={{ marginTop: 2 }}>
            {users.map((user) => (
              <div key={user.name}>
                <StyledListItem
                  disableGutters
                  button
                  onClick={() => handleSelectUser(user)}
                  sx={{
                    backgroundColor: selectedUser === user ? "#f5f5f5" : "#fff",
                    "&:hover": {
                      backgroundColor: "#f5f5f5",
                    },
                  }}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      variant: "subtitle1",
                      color: selectedUser === user ? "primary" : "initial",
                      fontWeight: selectedUser === user ? "600" : "400",
                    }}
                    primary={user.name}
                    secondary={user.email}
                  />
                </StyledListItem>
              </div>
            ))}
          </List>
        ) : (
          <Typography variant="subtitle1">No users found.</Typography>
        )}
      </Box>

      {/* Messaging Window */}
      <Box sx={{ flex: 1, overflow: "auto", padding: 2 }}>
        {selectedUser && (
          <>
            <Typography variant="h6">{selectedUser.name}</Typography>
            <Typography variant="subtitle1">{selectedUser.email}</Typography>
            <Divider sx={{ marginTop: 1, marginBottom: 2 }} />
            {messages.map((message) => (
              <div key={message.id}>
                <Typography variant="body1">{message.message}</Typography>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  sx={{ display: "block", textAlign: "right", marginTop: 1 }}
                >
                  {message.datetime}
                </Typography>
                <Divider sx={{ marginTop: 2 }} />
              </div>
            ))}
            <Divider sx={{ marginTop: 2, marginBottom: 1 }} />
           {/*  <Box sx={{ display: "flex", marginTop: 2 }}>
              <TextField
                fullWidth
                placeholder="Reply message"
                value={replyMessage}
                onChange={handleChangeReplyMessage}
                variant="outlined"
                disabled = "true"
                sx={{ marginRight: 1 }}
              />
              <Button
                variant="contained"
                endIcon={<SendIcon />}
                onClick={handleReplyToUser}
              >
                Reply
              </Button>
            </Box> */}
          </>
        )}
        {!selectedUser && (
          <Typography variant="subtitle1">No user selected.</Typography>
        )}
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default UserMailDataGrid;
