import React, { useState, useEffect } from "react";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { firestore } from "../../firebaseConfig";
import {
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  Divider,
  Snackbar,
  SnackbarContent,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import jsonexport from "jsonexport/dist";
import { CSVLink } from "react-csv";
import { useMediaQuery, useTheme } from "@mui/material";

const AttendingDataGrid = () => {
  const [rows, setRows] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [csvData, setCsvData] = useState(null);
  const [weddingCount, setWeddingCount] = useState(0);
  const [cocktailCount, setCocktailCount] = useState(0);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [selectedRSVP, setSelectedRSVP] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    fetchRsvpResponses();
  }, []);

  const fetchRsvpResponses = async () => {
    const rsvpCollection = collection(firestore, "rsvp");
    const rsvpSnapshot = await getDocs(rsvpCollection);
    const rsvpList = rsvpSnapshot.docs.map((doc) => {
      const rsvpData = doc.data();
      const mainGuest = {
        name: rsvpData.guests[0]?.name,
        age: rsvpData.guests[0]?.age,
      };
      const additionalGuests = rsvpData.guests.slice(1);
      return {
        id: doc.id,
        mainGuestName: mainGuest.name,
        mainGuestAge: mainGuest.age,
        additionalGuests: additionalGuests,
        attendingWedding: rsvpData.wedding === "yes",
        attendingCocktail: rsvpData.cocktail === "yes",
      };
    });

    const weddingCount = rsvpList.reduce((count, row) => {
      if (row.attendingWedding) {
        return count + row.additionalGuests.length + 1;
      } else {
        return count;
      }
    }, 0);
    const cocktailCount = rsvpList.reduce(
      (count, row) =>
        count + (row.attendingCocktail ? row.additionalGuests.length + 1 : 0),
      0
    );
    setRows(rsvpList);
    setWeddingCount(weddingCount);
    setCocktailCount(cocktailCount);
  };

  const handleSearchNameChange = (event) => {
    setSearchName(event.target.value);
  };

  const filteredRows = rows.filter(
    (row) =>
      row.mainGuestName.toLowerCase().includes(searchName.toLowerCase()) ||
      row.additionalGuests.some((guest) =>
        guest.name.toLowerCase().includes(searchName.toLowerCase())
      )
  );

  useEffect(() => {
    const exportToCsv = async () => {
      const data = filteredRows.map((row) => ({
        "Main Guest": row.mainGuestName,
        Age: row.mainGuestAge,
        Wedding: row.attendingWedding ? "Yes" : "No",
        Cocktail: row.attendingCocktail ? "Yes" : "No",
        "Additional Guests": row.additionalGuests
          .map(
            (guest) =>
              `Name: ${guest.name}, Age: ${guest.age}, Relationship: ${guest.relationship}`
          )
          .join("\n"),
      }));
      const csv = await jsonexport(data);
      setCsvData(csv); // Update CSV data in state
    };

    exportToCsv(); // Call exportToCsv() whenever 'filteredRows' changes
  }, [filteredRows]);

  const handleOpenConfirmationDialog = (rsvp) => {
    setSelectedRSVP(rsvp);
    setShowConfirmationDialog(true);
  };

  const handleCloseConfirmationDialog = () => {
    setSelectedRSVP(null);
    setShowConfirmationDialog(false);
  };

  const handleDeleteRSVP = async () => {
    try {
      // Delete the RSVP entry using the selected RSVP's 'id'
      await deleteDoc(doc(firestore, "rsvp", selectedRSVP.id));

      // Show success snackbar message
      setShowSnackbar(true);
      setSnackbarMessage("RSVP deleted successfully.");

      // Fetch updated RSVP responses
      fetchRsvpResponses();
    } catch (error) {
      // Show error snackbar message
      setShowSnackbar(true);
      setSnackbarMessage("Failed to delete RSVP.");
    }

    // Close the confirmation dialog
    handleCloseConfirmationDialog();
  };

  return (
    <div style={{ padding: "16px" }}>
      <Typography variant="h6">Attending Data Grid</Typography>
      <TextField
        label="Search by Name"
        value={searchName}
        onChange={handleSearchNameChange}
        variant="outlined"
        size="small"
        fullWidth
        style={{ marginBottom: "16px" }}
      />
      <div>
        <Typography variant="subtitle1">
          Wedding Count: {weddingCount}
        </Typography>
        <Typography variant="subtitle1">
          Cocktail Count: {cocktailCount}
        </Typography>
      </div>
      <div style={{ marginTop: "16px", marginBottom: "16px" }}>
        <List>
          {filteredRows.map((row) => (
            <div key={row.id}>
              <ListItem>
                <ListItemText
                  primary={row.mainGuestName}
                  secondary={`Age: ${row.mainGuestAge} ${
                    row.attendingWedding ? "| Wedding: Yes" : "| Wedding: No"
                  } ${
                    row.attendingCocktail ? "| Cocktail: Yes" : "| Cocktail: No"
                  }`}
                />
                <IconButton
                  onClick={() => handleOpenConfirmationDialog(row)}
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              </ListItem>
              {row.additionalGuests.length > 0 && (
                <List disablePadding>
                  {row.additionalGuests.map((guest) => (
                    <ListItem key={guest.name} style={{ paddingLeft: "40px" }}>
                      <ListItemText
                        primary={guest.name}
                        secondary={`Age: ${guest.age}`}
                      />
                    </ListItem>
                  ))}
                </List>
              )}
              <Divider />
            </div>
          ))}
        </List>
      </div>
      <div style={{ textAlign: "center", marginTop: "16px" }}>
        <Button onClick={fetchRsvpResponses}>Refresh</Button>
        {csvData && (
          <CSVLink data={csvData} filename={"rsvp-responses.csv"}>
            Export to CSV
          </CSVLink>
        )}
      </div>
      <Dialog
        open={showConfirmationDialog}
        onClose={handleCloseConfirmationDialog}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this RSVP?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmationDialog}>Cancel</Button>
          <Button onClick={handleDeleteRSVP} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowSnackbar(false)}
      >
        <SnackbarContent message={snackbarMessage} />
      </Snackbar>
    </div>
  );
};

export default AttendingDataGrid;
