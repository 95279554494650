import React from "react";

function LiveStream2() {
  return (
    <div>
      <h2>Join the Meeting</h2>
      <p>Click the link below to join the Zoom meeting:</p>
      <a href="https://us06web.zoom.us/j/88265093498?pwd=bEIwTkdSUjVtMnJPR3JEcEJOZDVoQT09">
        https://us06web.zoom.us/j/88265093498?pwd=bEIwTkdSUjVtMnJPR3JEcEJOZDVoQT09
      </a>
    </div>
  );
}

export default LiveStream2;
