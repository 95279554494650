import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Container,
  TextField,
  Button,
  Typography,
  Snackbar,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import { styled } from "@mui/system";
import "../../fonts.css";

const FormContainer = styled(Container)(({ theme }) => ({
  backgroundColor: "#f2a530",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  overflow: "hidden",
}));

const FormContent = styled(Container)(({ theme }) => ({
  position: "relative",
  zIndex: 1,
}));

const StyledForm = styled("form")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "24px",
  backgroundColor: "#fff",
  borderRadius: "4px",
  position: "relative",
  height: "100%",
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: "#557054",
  fontFamily: "Zeyada",
  fontSize: "25px",
  marginBottom: "16px",
  justifyContent: "center",
  fontWeight: "bold",
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: "16px",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: "16px",
  marginLeft: "10px",
  marginRight: "10px",
  backgroundColor: "#557054",
  color: "white",
  "&:hover": { backgroundColor: "#2d402c" },
}));

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Get token from URL
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  // Check if token is valid on component mount
  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axios.get(
          `https://us-central1-wedding-f79a0.cloudfunctions.net/checkToken?token=${token}`
        );
        if (!response.data.success) {
          navigate("/login");
        }
      } catch (error) {
        navigate("/login");
      }
    };
    checkToken();
  }, []);

  const validatePassword = () => {
    // Validate password criteria
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  };

  const resetPassword = async () => {
    if (password !== confirmPassword) {
      setMessage("Passwords do not match.");
      return;
    }

    if (!validatePassword()) {
      setMessage(
        "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character."
      );
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        "https://us-central1-wedding-f79a0.cloudfunctions.net/resetPassword",
        { token, newPassword: password }
      );
      if (response.data.success) {
        setMessage("Password reset successful.");
        navigate("/dashboard");
      } else {
        setMessage(response.data.message);
      }
    } catch (error) {
      setMessage("An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormContainer>
      <FormContent maxWidth="sm">
        <StyledForm>
          <StyledTypography variant="h5" component="h1" gutterBottom>
            Create Password
          </StyledTypography>
          <StyledTextField
            type="password"
            label="New Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <StyledTextField
            type="password"
            label="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <StyledButton onClick={resetPassword} disabled={loading}>
            {loading ? "Creating..." : "Create Password"}
          </StyledButton>
          
        </StyledForm>
      </FormContent>

      <Snackbar
        open={message !== ""}
        autoHideDuration={3000}
        onClose={() => setMessage("")}
      >
        <Alert onClose={() => setMessage("")} severity="error">
          {message}
        </Alert>
      </Snackbar>
    </FormContainer>
  );
};

export default ResetPassword;
