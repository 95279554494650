import React, { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Typography,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Menu as MenuIcon, Close as CloseIcon } from "@mui/icons-material";
import { getDocs, collection, query, where } from "firebase/firestore";
import { auth, firestore } from "../../firebaseConfig";
import { styled } from "@mui/material/styles";
import ButtonBase from "@mui/material/ButtonBase";
import background from "../../assets/images/backgrounds/IMG_0741.JPG";

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: "relative",
  height: 100,
  width: "auto",
  "&:hover, &.Mui-focusVisible": {
    zIndex: 1,
    "& .MuiTypography-root": {
      border: "4px solid currentColor",
    },
  },
  [theme.breakpoints.down("sm")]: {
    width: "100% !important",
    height: 50,
  },
}));

const ButtonContent = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  width: "100%",
  padding: theme.spacing(1),
  boxSizing: "border-box",
}));

const CustomAppBar = styled("div")(({ theme }) => ({
  position: "sticky",
  top: 0,
  width: "100%",
  zIndex: 1,
  overflow: "hidden",
  background: "#f2a530",
  boxShadow: "none",
  overflowX: "hidden",
  overflowY: "hidden",
}));

const CustomToolbar = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  minHeight: "56px",
  padding: theme.spacing(2),
  [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
    minHeight: "48px",
  },
  [theme.breakpoints.up("sm")]: {
    minHeight: "64px",
  },
}));

const MobileMenuButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const MobileSidebar = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    width: "250px",
  },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1),
}));

const buttons = [
  { title: "Dashboard", route: "/dashboard" },
  { title: "Gallery", route: "/gallery" },
  { title: "Events", route: "/events" },
  { title: "RSVP", route: "/rsvp" },
  { title: "Contact", route: "/contact" },
  { title: "Profile", route: "/profile" },
  { title: "LiveStream", route: "/stream" },
];

const Header = ({ user, isAdmin }) => {
  const navigate = useNavigate();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate("/");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(true);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuOpen(false);
  };

  const renderButtons = () => {
    if (window.innerWidth < 600) {
      return null; // Don't render buttons on mobile screens
    }

    return (
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          minWidth: 300,
          width: "100%",
          justifyContent: "flex-end",
        }}
      >
        {isAdmin && (
          <ImageButton component={RouterLink} to="/admin">
            <ButtonContent>
              <Typography
                component="span"
                variant="subtitle1"
                color="inherit"
                sx={{
                  position: "relative",
                  maxWidth: "100%",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  p: 2,
                }}
              >
                Admin Tools
              </Typography>
            </ButtonContent>
          </ImageButton>
        )}

        {buttons.map((button) => (
          <ImageButton
            key={button.title}
            component={RouterLink}
            to={button.route}
            style={{
              width: "auto",
            }}
          >
            <ButtonContent>
              <Typography
                component="span"
                variant="subtitle1"
                color="inherit"
                sx={{
                  position: "relative",
                  maxWidth: "100%",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  p: 2,
                }}
              >
                {button.title}
              </Typography>
            </ButtonContent>
          </ImageButton>
        ))}
        <ButtonBase
          onClick={handleLogout}
          variant="text"
          sx={{
            fontFamily: "roboto",
            ml: 1,
            color: "black",
            textDecoration: "none",
            "&:hover": { color: "white" },
          }}
        >
          Logout
        </ButtonBase>
      </Box>
    );
  };

  return (
    <CustomAppBar>
      <CustomToolbar>
        <MobileMenuButton
          color="inherit"
          edge="start"
          onClick={handleMobileMenuOpen}
        >
          <MenuIcon />
        </MobileMenuButton>
        <Typography
          component={RouterLink}
          to="/dashboard"
          sx={{
            flexGrow: 1,
            color: "#557054",
            fontFamily: "Zeyada",
            textDecoration: "none",
            fontSize: '40px',
            fontWeight: 'bold',
          }}
        >
          Nupur and Kaushik's Wedding
        </Typography>
        {renderButtons()}
      </CustomToolbar>
      <MobileSidebar
        anchor="left"
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        {/* <CloseButton onClick={handleMobileMenuClose}>
          <CloseIcon />
        </CloseButton> */}
        <List>
          {isAdmin && (
            <ListItem button component={RouterLink} to="/admin">
              <ListItemText primary="Admin Tools" />
            </ListItem>
          )}
          {buttons.map((button) => (
            <ListItem
              key={button.title}
              button
              component={RouterLink}
              to={button.route}
            >
              <ListItemText primary={button.title} />
            </ListItem>
          ))}
          <ListItem button onClick={handleLogout}>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </MobileSidebar>
    </CustomAppBar>
  );
};

export default Header;
