import React, { useState, useEffect } from "react";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

import {
  Container,
  TextField,
  Button,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import { styled } from "@mui/system";
import "../../fonts.css";
import { auth } from "../../firebaseConfig";


const FormContainer = styled(Container)(({ theme }) => ({
  backgroundColor: "#f2a530",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  overflow: "hidden",
}));

const FormContent = styled(Container)(({ theme }) => ({
  position: "relative",
  zIndex: 1,
}));

const StyledForm = styled("form")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "24px",
  backgroundColor: "#fff",
  borderRadius: "4px",
  position: "relative",
  height: "100%",
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: "#557054",
  fontFamily: "Zeyada",
  fontSize: "25px",
  marginBottom: "16px",
  justifyContent: "center",
  fontWeight: "bold",
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: "16px",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: "16px",
  marginLeft: "10px",
  marginRight: "10px",
  backgroundColor: "#557054",
  color: "white",
  "&:hover": { backgroundColor: "#2d402c" },
}));




const UserOnboard = ({ user, firestore }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [relationship, setRelationship] = useState("");
  const navigate = useNavigate();
  const [openSnackbar, setOpenSnackbar] = useState(false);
 

   useEffect(() => {
     const fetchUserData = async () => {
       const userInfoRef = doc(firestore, "userInfo", user.uid);
       const docSnap = await getDoc(userInfoRef);

       if (
         docSnap.exists() &&
         docSnap.data().firstName &&
         docSnap.data().lastName &&
         docSnap.data().relationship
       ) {
         navigate("/dashboard");
       }
     };

     fetchUserData();
   }, [user, firestore, navigate]);
 const submitForm = async (e) => {
   e.preventDefault();

   const userRef = doc(firestore, "userInfo", user.uid);
   await setDoc(
     userRef,
     { uid: user.uid, firstName, lastName, relationship },
     { merge: true }
   );

   navigate("/dashboard");
 };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate("/");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
    navigate("/dashboard");
  };

  return (
    <FormContainer>
      <FormContent maxWidth="sm">
        <StyledForm onSubmit={submitForm}>
          <StyledTypography variant="h5" component="h1" gutterBottom>
            Welcome To Nupur & Kaushik's Wedding Web App
          </StyledTypography>
          <Typography variant="subtitle1" gutterBottom>
            Please Fill Out This Information To Proceed
          </Typography>
          <StyledTextField
            type="text"
            label="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          <StyledTextField
            type="text"
            label="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
          <StyledTextField
            type="text"
            label="Relationship with Host"
            value={relationship}
            onChange={(e) => setRelationship(e.target.value)}
            required
          />
          <div style={{flexDirection:'column',justifyContent:'space-between'}}>
            <StyledButton type="submit" variant="contained" color="primary">
              Submit
            </StyledButton>
            <StyledButton type="submit" variant="contained" color="primary" onClick={handleLogout}>
              Logout
            </StyledButton>
          </div>
        </StyledForm>
      </FormContent>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          Profile saved successfully
        </Alert>
      </Snackbar>
    </FormContainer>
  );
};

export default UserOnboard;
