import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import LazyLoad from "react-lazyload";
import image1 from "../assets/images/ourstory/26.jpg";
import image2 from "../assets/images/ourstory/2.jpg";
import image3 from "../assets/images/ourstory/27.jpg";
import image4 from "../assets/images/ourstory/25.jpg";
import image5 from "../assets/images/ourstory/5.jpg";
import image6 from "../assets/images/ourstory/7.jpg";
import image8 from "../assets/images/ourstory/8.jpg";
import image10 from "../assets/images/ourstory/10.jpg";
import image11 from "../assets/images/ourstory/21.jpg";
import image12 from "../assets/images/ourstory/12.jpg";
import image13 from "../assets/images/ourstory/18.jpg";
import image14 from "../assets/images/ourstory/22.jpg";
import image15 from "../assets/images/ourstory/23.jpg";
import image16 from "../assets/images/ourstory/16.jpg";
import image17 from "../assets/images/ourstory/17.jpg";

import patrika from "../assets/images/backgrounds/background10.png";

import "../fonts.css"; // Import the fonts.css file

const OurStoryPage = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  minHeight: "100vh",
  color: "#ffffff",
  padding: "200px 0",
  backgroundImage: `url(${patrika})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundAttachment: "fixed", // Add this line
}));

const Heading = styled(Typography)(({ theme }) => ({
  fontSize: "80px",
  paddingTop: "10px",
  marginBottom: "40px",
  fontFamily: "Zeyada",
  color: "#557054",
  fontWeight: "normal",
  display: "inline-block",
  lineHeight: 1.2,
  borderRadius:2,
  borderColor: 'black'
}));

const ImageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "40px",
}));

const Image = styled("img")(({ theme }) => ({
  width: "200px",
  height: "200px",
  borderRadius: "50%",
  objectFit: "cover",
  margin: "10px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
  border: "5px solid #fff",
  transition: "transform 0.3s ease",
  "&:hover": {
    transform: "scale(1.1)",
  },
}));

const Description = styled(Typography)(({ theme }) => ({
  maxWidth: "800px",
  margin: "0 auto",
  marginTop: "40px",
  textAlign: "center",
  fontSize: "40px",
  fontFamily: "Satisfy",
  fontWeight: "normal",
  color: "#557054",
}));

function Dashboard() {
  return (
    <OurStoryPage>
      <Heading variant="h1" component="h1" gutterBottom>
        Kaushik and Nupur
      </Heading>
      <Heading variant="h1" component="h1" gutterBottom>
        4th of July, 2023, Calabasas, CA
      </Heading>
      <Description variant="body1" component="p" gutterBottom>
        Welcome to our online celebration hub! We are thrilled to have you
        virtually join us as we embark on this incredible journey of love and
        commitment. This website is your one-stop destination for all things
        wedding-related, where you'll find everything from event details to
        quirky stories that led us to this moment. So grab a virtual seat, kick
        back, and get ready to share in our joy and laughter. We can't promise
        you a royal wedding, but we can promise you a grand feast, amazing
        music, and plenty of awkward dancing from our family members. Get ready
        to boogie!
      </Description>
      <ImageContainer>
        <LazyLoad>
          <Image src={image1} alt="Image 1" />
        </LazyLoad>
        <LazyLoad>
          <Image src={image2} alt="Image 2" />
        </LazyLoad>
        <LazyLoad>
          <Image src={image3} alt="Image 3" />
        </LazyLoad>
        <LazyLoad>
          <Image src={image4} alt="Image 4" />
        </LazyLoad>
      </ImageContainer>
      <LazyLoad>
        <Description variant="body1" component="p" gutterBottom>
          Our hilarious love story began nine years ago when we crossed paths
          during a college annual function. Imagine this: cheesy music blaring,
          everyone attempting terrible dance moves, and the unmistakable aroma
          of cafeteria food wafting through the air. But what truly made it
          unforgettable was the moment I auditioned to be the emcee, only to
          have Kaushik transform into a lecturer, schooling me with famous
          sitcom dialogues on how to be a successful emcee. Little did I know
          that this playful banter would be the start of a beautiful journey
          together.
        </Description>
      </LazyLoad>

      <ImageContainer>
        <LazyLoad>
          <Image src={image6} alt="Image 6" />
        </LazyLoad>
        <LazyLoad>
          <Image src={image8} alt="Image 8" />
        </LazyLoad>
        <LazyLoad>
          <Image src={image10} alt="Image 10" />
        </LazyLoad>
        <LazyLoad>
          <Image src={image11} alt="Image 11" />
        </LazyLoad>
        <LazyLoad>
          <Image src={image13} alt="Image 13" />
        </LazyLoad>
      </ImageContainer>
      <LazyLoad>
        <Description variant="body1" component="p" gutterBottom>
          As we got to know each other, we discovered we wanted the same things
          in life (mainly endless panipuris) and shared the same values (like
          always leaving room for ladoos). Our dating adventures have taken us
          on thrilling escapades and cozy nights as couch potatoes, creating a
          perfect balance between excitement and relaxation.
        </Description>
      </LazyLoad>
      <ImageContainer>
        <LazyLoad>
          <Image src={image14} alt="Image 14" />
        </LazyLoad>
        <LazyLoad>
          <Image src={image17} alt="Image 15" />
        </LazyLoad>
        <LazyLoad>
          <Image src={image16} alt="Image 16" />
        </LazyLoad>
        <LazyLoad>
          <Image src={image15} alt="Image 17" />
        </LazyLoad>
      </ImageContainer>
      <LazyLoad>
        <Description variant="body1" component="p" gutterBottom>
          Oh, and let's not forget our adorable monster puppy named Mango. He is
          cute, and he knows it! From chewing our shoes to stealing the
          spotlight in every family photo, Mango keeps us on our toes and our
          hearts overflowing with love.
        </Description>
        <Description variant="body1" component="p" gutterBottom>
          Now, here we are, about to embark on our wedding journey, and we
          couldn't be more excited. We are incredibly grateful for the love and
          support we've received along the way, and we can't wait to share this
          joyous day with all of you. Get ready for some unforgettable moments,
          laughter, and maybe even a few embarrassing dance moves. Thank you for
          being a part of our lives and joining us on this wild and wonderful
          adventure!"
        </Description>
      </LazyLoad>
    </OurStoryPage>
  );
}

export default Dashboard;
