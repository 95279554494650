import React, { useEffect, useState } from "react";
import {
  Card,
  CardActions,
  IconButton,
  Typography,
  Dialog,
  DialogActions,
  Button,
  useMediaQuery,
  useTheme,
  List,
  ListItem,
  ListItemText,
  DialogContent
} from "@mui/material";
import { Favorite, Share, Delete } from "@mui/icons-material";
import moment from "moment";
import ReactPlayer from "react-player";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { collection, doc, getDoc, firestore} from "../../firebaseConfig";
import Comments from "./Comments";

const MediaCard = ({
  media,
  handleLike,
  handleShare,
  handleDelete,
  handleOpenDialog,
  isAdmin,
}) => {
  const [open, setOpen] = useState(false);
  const [openLikedUsersDialog, setOpenLikedUsersDialog] = useState(false);
  const [likedUsers, setLikedUsers] = useState([]);
const [userInfo, setUserInfo] = useState({ firstName: "", lastName: "" });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLikedUsersDialogOpen = () => {
    setOpenLikedUsersDialog(true);
  };

  const handleLikedUsersDialogClose = () => {
    setOpenLikedUsersDialog(false);
  };

  const likeColor = media.likes.includes(getAuth().currentUser.uid)
    ? "secondary"
    : "disabled";

  const handleLikesClick = async () => {
    try {
      const likedUsers = [];
      console.log("clicked");

      // Initialize Firestore and get a reference to the users collection
  
      const usersCollection = collection(firestore, "userInfo");

      // Fetch user information from Firestore based on the liked user IDs
      for (const userId of media.likes) {
        const userRef = doc(usersCollection, userId);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          const user = userDoc.data();
          likedUsers.push({
            uid: userId,
            firstName: user.firstName,
            lastName: user.lastName,
          });
        }
      }
      console.log(likedUsers);
      setLikedUsers(likedUsers);
      handleLikedUsersDialogOpen(); // Open the liked users dialog
    } catch (error) {
      console.error("Error loading liked users:", error);
    }
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      const db = getFirestore();
      const userRef = doc(db, "userInfo", media.uploadedBy);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        setUserInfo(userSnap.data());
      } else {
        setUserInfo({ firstName: "", lastName: media.userEmail });
      }
    };

    fetchUserInfo();
  }, [media]);


  return (
    <Card>
      <Typography
        variant="caption"
        display="block"
        gutterBottom
        sx={{ marginLeft: "10px", marginTop: "10px" }}
      >
        Posted by:{" "}
        {userInfo.firstName || userInfo.lastName
          ? `${userInfo.firstName} ${userInfo.lastName}`
          : media.userEmail}
      </Typography>
      <div
        style={{
          width: "100%",
          paddingBottom: isMobile ? "50%" : "48%",
          position: "relative",
          margin: 2,
          paddingTop: isMobile ? "50%" : "48%",
        }}
      >
        {media.type === "video" ? (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "100%",
              height: "100%",
              overflow: "hidden",
            }}
          >
            <ReactPlayer
              url={media.url}
              controls
              light={media.thumbnailURL}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                maxWidth: "100%",
                maxHeight: "100%",
              }}
            />
          </div>
        ) : (
          <img
            src={media.url}
            alt="Uploaded media"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "100%",
              height: "100%",
              objectFit: "contain",
              cursor: "pointer",
            }}
            onClick={handleClickOpen}
          />
        )}
        <div
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            background: "white",
            padding: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="caption" color="black">
              Uploaded {moment(media.createdAt).fromNow()}
            </Typography>
            <Typography
              variant="caption"
              color="black"
              onClick={handleLikesClick}
              style={{ cursor: "pointer" }}
            >
              {media.likes.length === 0 ? (
                ""
              ) : (
                <>
                  {media.likes.length}{" "}
                  {media.likes.length === 1 ? "like" : "likes"}
                </>
              )}
            </Typography>
          </div>
          <div style={{ marginRight: "20px" }}>
            <IconButton
              onClick={() => handleLike(media.id)}
              aria-label="like"
              color={likeColor}
            >
              <Favorite />
            </IconButton>
            {/*  <IconButton onClick={() => handleShare(media)} aria-label="share">
              <Share />
            </IconButton> */}
            {(isAdmin || media.uploadedBy === getAuth().currentUser.uid) && (
              <IconButton
                onClick={() => handleDelete(media.id)}
                aria-label="delete"
              >
                <Delete />
              </IconButton>
            )}
          </div>
        </div>
      </div>

      <Dialog open={open} onClose={handleClose}>
        <img
          src={media.url}
          alt="Uploaded media"
          style={{ width: "100%", height: "auto" }}
        />
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button component="a" href={media.url} download color="primary">
            Download
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openLikedUsersDialog}
        onClose={handleLikedUsersDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent dividers>
          <List sx={{ maxHeight: 200, overflow: "auto" }}>
            {likedUsers.map((user) => (
              <ListItem key={user.uid}>
                <ListItemText primary={`${user.firstName} ${user.lastName}`} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLikedUsersDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default MediaCard;
