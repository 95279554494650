import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { deleteObject } from "firebase/storage";
import { getStorage, ref } from "firebase/storage";
import { auth, firestore, getDoc } from "../../firebaseConfig";
import {
  Grid,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  Typography,
} from "@mui/material";
import { Facebook, Instagram, WhatsApp, Email } from "@mui/icons-material";
import UploadSection from "./UploadSection";
import MediaCard from "./MediaCard";

import { styled } from "@mui/system";

const Description = styled(Typography)(({ theme }) => ({
  maxWidth: "800px",
  margin: "0 auto",
  marginTop: "10px",
  textAlign: "center",
  fontSize: "20px",
  fontFamily: "Satisfy",
  fontWeight: "bold",
  color: "#557054",
}));

const Gallery = () => {
  const [mediaList, setMediaList] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteMediaId, setDeleteMediaId] = useState("");
  const storage = getStorage();
  const [isAdmin, setIsAdmin] = useState(false);

  const fetchMedia = async () => {
    const mediaCollection = collection(firestore, "media");
    const mediaSnapshot = await getDocs(mediaCollection);
    const mediaData = mediaSnapshot.docs
      .map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
      .sort((a, b) => b.createdAt - a.createdAt);
    setMediaList(mediaData);
    // fetch the user role
    const userRolesCollection = collection(firestore, "userRoles");
    const userRoleSnapshot = await getDocs(userRolesCollection);
    const userRoleData = userRoleSnapshot.docs
      .map((doc) => doc.data())
      .find((roleData) => roleData.uuid === auth.currentUser.uid);

    // update isAdmin state
    setIsAdmin(userRoleData && userRoleData.role === "admin");
  };


  useEffect(() => {
    fetchMedia();
  }, []);

  const handleLike = async (mediaId) => {
    const mediaRef = doc(firestore, "media", mediaId);
    const mediaSnapshot = await getDoc(mediaRef);
    const currentLikes = mediaSnapshot.data().likes;

    if (currentLikes.includes(auth.currentUser.uid)) {
      const newLikes = currentLikes.filter(
        (uid) => uid !== auth.currentUser.uid
      );
      await updateDoc(mediaRef, {
        likes: newLikes,
      });
    } else {
      await updateDoc(mediaRef, {
        likes: [...currentLikes, auth.currentUser.uid],
      });
    }

    fetchMedia();
  };

  const handleShare = (media) => {
    const shareURL = media.url;
    setSelectedMedia(media);
    setOpenDialog(true);
    handleOpenShareDialog(shareURL);
  };

  const handleDelete = (mediaId) => {
    setDeleteMediaId(mediaId);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const mediaRef = doc(firestore, "media", deleteMediaId);
      const mediaSnapshot = await getDoc(mediaRef);
      const mediaData = mediaSnapshot.data();

      // fetch the user role
      const userRolesCollection = collection(firestore, "userRoles");
      const userRoleSnapshot = await getDocs(userRolesCollection);
      const userRoleData = userRoleSnapshot.docs
        .map((doc) => doc.data())
        .find((roleData) => roleData.uuid === auth.currentUser.uid);

      // only proceed if user is an admin or the original uploader
      if (
        (userRoleData && userRoleData.role === "admin") ||
        mediaData.uploadedBy === auth.currentUser.uid
      ) {
        // Delete the media file from storage
        const storageRef = ref(storage, `media/${mediaData.fileName}`);
        await deleteObject(storageRef);

        // Delete the media document from Firestore
        await deleteDoc(mediaRef);

        setOpenSnackbar(true);
        setSnackbarMessage("Media deleted successfully!");
        fetchMedia();
      } else {
        setOpenSnackbar(true);
        setSnackbarMessage("You are not authorized to delete this media.");
      }
    } catch (error) {
      console.error("Error deleting media:", error);
      setOpenSnackbar(true);
      setSnackbarMessage("Error deleting media. Please try again.");
    } finally {
      setDeleteDialogOpen(false);
      setDeleteMediaId("");
    }
  };


  const handleOpenDialog = (media) => {
    setSelectedMedia(media);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setSelectedMedia(null);
    setOpenDialog(false);
  };

  const handleOpenShareDialog = (shareURL) => {
    const dialogTitle = "Share Media";
    const dialogContent = (
      <div>
        <Button
          onClick={() => handleSharePlatform(shareURL, "facebook")}
          fullWidth
        >
          <Facebook />
          <Typography>Share on Facebook</Typography>
        </Button>
        <Button
          onClick={() => handleSharePlatform(shareURL, "instagram")}
          fullWidth
        >
          <Instagram />
          <Typography>Share on Instagram</Typography>
        </Button>
        <Button
          onClick={() => handleSharePlatform(shareURL, "whatsapp")}
          fullWidth
        >
          <WhatsApp />
          <Typography>Share on WhatsApp</Typography>
        </Button>
        <Button
          onClick={() => handleSharePlatform(shareURL, "email")}
          fullWidth
        >
          <Email />
          <Typography>Share via Email</Typography>
        </Button>
      </div>
    );
    const dialogActions = (
      <Button onClick={handleCloseDialog} color="primary">
        Close
      </Button>
    );

    setOpenDialog(true);
    setSelectedMedia({
      title: dialogTitle,
      description: dialogContent,
      actions: dialogActions,
    });
  };

  const handleSharePlatform = (shareURL, platform) => {
    let shareLink = shareURL;
    if (!shareLink.startsWith("http") && !shareLink.startsWith("//")) {
      shareLink = window.location.origin + "/" + shareLink;
    }

    switch (platform) {
      case "facebook":
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${shareLink}`
        );
        break;
      case "instagram":
        window.open(`https://www.instagram.com/share?url=${shareLink}`);
        break;
      case "whatsapp":
        window.open(`https://wa.me/?text=${encodeURIComponent(shareLink)}`);
        break;
      case "email":
        window.open(`mailto:?subject=&body=${encodeURIComponent(shareLink)}`);
        break;
      default:
        break;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        /*   backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${patrika})`, */
      }}
    >
      <Description variant="body1" component="p" gutterBottom>
        Oh Snap...! We know you've got your trusty phone camera ready to capture
        all the wild and hilarious moments throughout the events. So, go ahead
        and unleash your inner paparazzi skills, snap away, and get ready to
        share your hilarious creations with us. We can't wait to see what
        photographic masterpieces and unexpected candid shots you'll come up
        with. Let the fun-filled photo frenzy begin!
      </Description>

      <UploadSection fetchMedia={fetchMedia} />
      <Grid container spacing={2}>
        {mediaList.map((media) => (
          <Grid item xs={12} md={6} lg={4} key={media.url}>
            <MediaCard
              media={media}
              handleLike={handleLike}
              handleShare={handleShare}
              handleDelete={handleDelete}
              handleOpenDialog={handleOpenDialog}
              isAdmin={isAdmin}
            />
          </Grid>
        ))}
      </Grid>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{selectedMedia && selectedMedia.title}</DialogTitle>
        <DialogContent>
          {selectedMedia && selectedMedia.description}
        </DialogContent>
        <DialogActions>{selectedMedia && selectedMedia.actions}</DialogActions>
      </Dialog>
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Delete Media</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this media?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={handleConfirmDelete}
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Gallery;
