import React from "react";
import { Container, Typography, styled } from "@mui/material";
import "../../fonts.css";

const NotFoundContainer = styled(Container)(({ theme }) => ({
  backgroundColor: "#fff",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const NotFoundContent = styled(Container)(({ theme }) => ({
  position: "relative",
  zIndex: 1,
  height: "80vh",
  paddingTop: '200px',
  textAlign: "center",
  backgroundColor: "#f2a530",
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: "#557054",
  fontFamily: "Zeyada",
  fontSize: "25px",
  marginBottom: "16px",
  fontWeight: "bold",
}));

const NotFound = () => {
  return (
    <NotFoundContainer>
      <NotFoundContent>
        <StyledTypography variant="h5" component="h1" gutterBottom>
          404: Page Not Found
        </StyledTypography>
        <Typography variant="body1">
          The page you are looking for does not exist!
        </Typography>
      </NotFoundContent>
    </NotFoundContainer>
  );
};

export default NotFound;
