import React, { useState } from "react";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";


import "./Login.css";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const auth = getAuth();
  

 const handleLogin = async (e) => {
   e.preventDefault();
   try {
     const userCredential = await signInWithEmailAndPassword(
       auth,
       email,
       password
     );
     const user = userCredential.user;
     const idToken = await user.getIdToken();

     // Store the ID token in local storage.
     localStorage.setItem("firebaseToken", idToken);


     navigate("/dashboard");
   } catch (error) {
     console.log("Login Error:", error);
     setError("Wrong email or password.");
   }
 };


  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setError("Password reset email sent. Please check your inbox.");
    } catch (error) {
      console.log("Forgot Password Error:", error);
      setError("Error sending password reset email. Please try again.");
    }
  };

  return (
    <div className="container">
      <div className="login-form">
        <h2>Login</h2>

        <form onSubmit={handleLogin}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && <p className="error">{error}</p>}
          <button type="submit">Login</button>
        </form>
        <div className="forgot-password">
          <p onClick={handleForgotPassword}>Forgot Password?</p>
        </div>
      </div>
    </div>
  );
};

export default Login;
