import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "@mui/material";
import AttendingDataGrid from "./AttendingDataGrid";
import UserMailDataGrid from "./UserMailDataGrid";
import { auth, firestore } from "../../firebaseConfig";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import AddNewUser from "./AddNewUser";

const AdminTools = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState("attending");

  useEffect(() => {
    const currentUser = auth.currentUser;

    const checkAdminRole = async () => {
      if (currentUser) {
        const rolesRef = collection(firestore, "userRoles");
        const q = query(rolesRef, where("uuid", "==", currentUser.uid));
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
          if (doc.data().role === "admin") {
            setIsAdmin(true);
          } else {
            setIsAdmin(false);
            navigate("/login");
          }
        });
      } else {
        navigate("/login");
      }
    };

    checkAdminRole();
  }, [navigate]);

  const handleTabChange = (event, newTab) => {
    setCurrentTab(newTab);
  };

  if (!isAdmin) {
    return null;
  }

  return (
    <div>
      <h2>Admin Tools</h2>
      <Tabs value={currentTab} onChange={handleTabChange}>
        <Tab label="Attending" value="attending" />
        <Tab label="User Mails" value="usermail" />
        <Tab label="Users" value="newuser" />
      </Tabs>
      {currentTab === "attending" && <AttendingDataGrid />}
      {currentTab === "usermail" && <UserMailDataGrid />}
      {currentTab === "newuser" && <AddNewUser />}
    </div>
  );
};

export default AdminTools;
