import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Login from "./components/Login/Login";
import Dashboard from "./components/Dashboard";
import Events from "./components/Events/Events";
import Gallery from "./components/Gallery/Gallery";
import Rsvp from "./components/Rsvp/Rsvp";
import Contact from "./components/Contact/Contact";
import Header from "./utils/Header/Header";
import AdminTools from "./components/AdminTools/AdminTools";
import UserOnboard from "./components/UserOnboard/UserOnboard";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  getFirestore,
  query,
  collection,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { getStorage } from "firebase/storage";
import {
  FirebaseAppProvider,
  FirestoreProvider,
  AuthProvider,
  StorageProvider,
} from "reactfire";
import { firebaseConfig } from "./firebaseConfig";
import { useNavigate } from "react-router-dom";
import Profile from "./components/ProfileScreen/Profile";
import NotFound from "./utils/NotFound/NotFound";
import ResetPassword from "./utils/ResetPassword/ResetPassword";
import LiveStream from "./components/LiveStream/LiveStream";

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const showHeader =
    user &&
    location.pathname !== "/onboarding" &&
    location.pathname !== "/reset-password";

  const [adminLoading, setAdminLoading] = useState(true);
   const rtcProps = {
     appId: "f6fb2c5f8c51431c97688d63157f79a3",
     channel: "test",
     token: null, // enter your channel token as a string
   }; 

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);
      setLoading(false);

      // If the user is logged in, update the token
      if (user) {
        try {
          const token = await user.getIdToken(true);
          localStorage.setItem("firebaseToken", token);
        } catch (error) {
          console.error("Error getting token:", error);
        }
      } else {
        // If the user is logged out, clear the token
        localStorage.removeItem("firebaseToken");
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const checkAdminRole = async () => {
      setAdminLoading(true);
      if (user) {
        const userInfoRef = doc(firestore, "userInfo", user.uid);
        const docSnap = await getDoc(userInfoRef);

        if (
          !docSnap.exists() ||
          !(
            docSnap.data().firstName &&
            docSnap.data().lastName &&
            docSnap.data().relationship
          )
        ) {
          navigate("/onboarding");
        } else {
          const rolesRef = collection(firestore, "userRoles");
          const q = query(rolesRef, where("uuid", "==", user.uid));
          const querySnapshot = await getDocs(q);

          querySnapshot.forEach((doc) => {
            if (doc.data().role === "admin") {
              setIsAdmin(true);
            } else {
              setIsAdmin(false);
              navigate("/dashboard");
            }
          });
        }
      }
      setAdminLoading(false);
    };

    checkAdminRole();
  }, [user, navigate]);

  if (loading || adminLoading) {
    return <div>Loading...</div>;
  }

  if (
    !user &&
    location.pathname !== "/login" &&
    location.pathname !== "/reset-password"
  ) {
    return <Navigate to="/login" replace />;
  }

  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <FirestoreProvider sdk={firestore}>
        <AuthProvider sdk={auth} firebaseAppAuth={auth}>
          <StorageProvider sdk={storage}>
            <div className="app-container">
              {showHeader && user && <Header user={user} isAdmin={isAdmin} />}
              <div className="content-container">
                <Routes location={location}>
                  <Route
                    path="/login"
                    element={
                      user ? <Navigate to="/onboarding" replace /> : <Login />
                    }
                  />
                  <Route
                    path="/dashboard"
                    element={
                      user ? <Dashboard /> : <Navigate to="/login" replace />
                    }
                  />
                  <Route
                    path="/events"
                    element={
                      user ? <Events /> : <Navigate to="/login" replace />
                    }
                  />
                  <Route
                    path="/"
                    element={
                      user ? (
                        <Navigate to="/dashboard" replace />
                      ) : (
                        <Navigate to="/login" replace />
                      )
                    }
                  />
                  <Route
                    path="/gallery"
                    element={
                      user ? <Gallery /> : <Navigate to="/login" replace />
                    }
                  />
                  <Route
                    path="/stream"
                    element={
                      user ? <LiveStream /> : <Navigate to="/login" replace />
                    }
                  />
                  <Route
                    path="/rsvp"
                    element={user ? <Rsvp /> : <Navigate to="/login" replace />}
                  />
                  <Route
                    path="/contact"
                    element={
                      user ? <Contact /> : <Navigate to="/login" replace />
                    }
                  />
                 
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route
                    path="/profile"
                    element={
                      user ? (
                        <Profile user={user} firestore={firestore} />
                      ) : (
                        <Navigate to="/login" replace />
                      )
                    }
                  />
                  <Route
                    path="/admin"
                    element={
                      loading ? (
                        <div>Loading...</div>
                      ) : user ? (
                        isAdmin ? (
                          <AdminTools />
                        ) : (
                          <Navigate to="/dashboard" replace />
                        )
                      ) : (
                        <Navigate to="/login" replace />
                      )
                    }
                  />
                  <Route
                    path="/onboarding"
                    element={
                      user ? (
                        <UserOnboard user={user} firestore={firestore} />
                      ) : (
                        <Navigate to="/login" replace />
                      )
                    }
                  />
                  <Route
                    path="/*"
                    element={
                      user ? <NotFound /> : <Navigate to="/login" replace />
                    }
                  />
                </Routes>
              </div>
            </div>
          </StorageProvider>
        </AuthProvider>
      </FirestoreProvider>
    </FirebaseAppProvider>
  );
};

export default App;
