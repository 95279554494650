import React, { useState, useRef } from "react";
import { collection, addDoc } from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { auth, firestore } from "../../firebaseConfig";
import {
  Card,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  useTheme,
  Fab,
  LinearProgress,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { styled } from "@mui/system";

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#557054",
  color: "white",
  "&:hover": { backgroundColor: "#2d402c" },
}));

const UploadSection = ({ fetchMedia }) => {
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);
  const storage = getStorage();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openDialog, setOpenDialog] = useState(false);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);
  };

  const generateThumbnail = (videoFile) => {
    return new Promise((resolve) => {
      const video = document.createElement("video");
      video.preload = "metadata";
      video.src = URL.createObjectURL(videoFile);

      video.onloadedmetadata = () => {
        video.currentTime = video.duration / 2;
        video.onseeked = () => {
          const canvas = document.createElement("canvas");
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          canvas.toBlob((blob) => {
            const thumbnailFile = new File([blob], "thumbnail.png");
            const reader = new FileReader();
            reader.onloadend = () => {
              const thumbnailDataURL = reader.result;
              resolve(thumbnailDataURL);
            };
            reader.readAsDataURL(thumbnailFile);
          }, "image/png");
        };
      };
    });
  };

  const handleUpload = async () => {
    if (!files.length) return;

    setUploading(true);

    try {
      const uploadPromises = files.map(async (file) => {
        const fileName = file.name;

        const storageRef = ref(storage, `media/${fileName}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on("state_changed", (snapshot) => {
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
        });

        await uploadTask;
        const downloadURL = await getDownloadURL(storageRef);

        let thumbnailURL = "";
        if (file.type.startsWith("video/")) {
          const thumbnailDataURL = await generateThumbnail(file);
          const thumbnailFile = dataURLtoFile(
            thumbnailDataURL,
            "thumbnail.png"
          );
          const thumbnailFileName = fileName.split(".")[0] + ".png";
          const thumbnailRef = ref(
            storage,
            `media/thumbnails/${thumbnailFileName}`
          );
          await uploadBytesResumable(thumbnailRef, thumbnailFile);
          thumbnailURL = await getDownloadURL(thumbnailRef);
        }

        await addDoc(collection(firestore, "media"), {
          url: downloadURL,
          thumbnailURL: thumbnailURL,
          likes: [],
          comments: [],
          createdAt: Date.now(),
          uploadedBy: auth.currentUser.uid,
          userEmail: auth.currentUser.email, // new field
          type: file.type.split("/")[0], // 'image' or 'video'
          fileName: fileName,
        });
      });

      await Promise.all(uploadPromises);

      setFiles([]);
      setUploading(false);
      fetchMedia();
      handleClearUpload();
    } catch (error) {
      console.error("Error uploading media:", error);
      setFiles([]);
      setUploading(false);
    }
  };

  const handleClearUpload = () => {
    setFiles([]);
    fileInputRef.current.value = "";
  };

  const dataURLtoFile = (dataURL, filename) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      {isMobile ? (
        <Fab
          color="primary"
          aria-label="add"
          onClick={handleClickOpen}
          sx={{ marginBottom: "10px", marginTop: "20px" }}
        >
          <AddIcon />
        </Fab>
      ) : (
        <Card style={{ margin: "20px", textAlign: "center" }}>
          <input
            style={{
              marginLeft: "30px",
              marginTop: "20px",
              marginBottom: "20px",
            }}
            type="file"
            accept="image/*,video/*"
            multiple
            onChange={handleFileChange}
            ref={fileInputRef}
          />
          <StyledButton
            variant="contained"
            color="primary"
            disabled={uploading || files.length === 0}
            onClick={handleUpload}
          >
            {uploading ? (
              <>
                <CircularProgress size={24} />
                <span style={{ marginLeft: "8px" }}>Uploading</span>
              </>
            ) : (
              "Upload"
            )}
          </StyledButton>
          <StyledButton
            variant="contained"
            color="primary"
            disabled={uploading || files.length === 0}
            onClick={handleClearUpload}
            style={{ marginLeft: "10px", marginRight: "30px" }}
          >
            Clear
          </StyledButton>
          {uploading && (
            <LinearProgress variant="determinate" value={progress} />
          )}
        </Card>
      )}
      <Dialog open={openDialog} onClose={handleClose}>
        <DialogTitle>Upload File</DialogTitle>
        <DialogContent>
          <input
            type="file"
            accept="image/*,video/*"
            multiple
            onChange={handleFileChange}
          />
          {uploading && (
            <LinearProgress variant="determinate" value={progress} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleUpload}
            color="primary"
            disabled={uploading || files.length === 0}
          >
            {uploading ? (
              <>
                <CircularProgress size={24} />
                <span style={{ marginLeft: "8px" }}>Uploading</span>
              </>
            ) : (
              "Upload"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UploadSection;

