import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import background from "../../assets/images/backgrounds/IMG_0744.JPG";
import cocktailParty1 from "../../assets/images/misc/cocktailParty1.jpg";
import cocktailParty2 from "../../assets/images/misc/cocktailParty2.jpg";
import wedding1 from "../../assets/images/misc/wedding1.jpg";
import wedding2 from "../../assets/images/misc/wedding2.jpg";
import { FaMapMarkerAlt } from "react-icons/fa";


const EventsPage = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${background})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  minHeight: "100vh",
  color: "white",
  overflowY: "hidden",
  padding: theme.spacing(1),
  "@media (min-width:600px)": {
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    padding: theme.spacing(3),
  },
}));

const Event = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: theme.spacing(2),
  "@media (min-width:600px)": {
    margin: theme.spacing(3),
  },
}));

const CircleWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginBottom: "20px",
}));

const CircleImage = styled("img")(({ theme }) => ({
  width: "200px",
  height: "200px",
  borderRadius: "50%",
  objectFit: "cover",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
}));

const LocationWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginTop: "10px",
}));

const LocationIcon = styled(FaMapMarkerAlt)(({ theme }) => ({
  marginRight: "5px",
}));
const EventsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: "0",
  "@media (min-width:600px)": {
    flexDirection: "row",
    justifyContent: "center",
    marginLeft: "150px", // Apply marginLeft for web screens
  },
}));
function Events() {
  return (
    <EventsPage>
      <EventsContainer>
        <Event>
          <CircleWrapper>
            <CircleImage src={cocktailParty2} alt="Cocktail Party" />
          </CircleWrapper>
          <Typography variant="h3" component="h3" gutterBottom>
            Cocktails
          </Typography>
          <Typography variant="body1" component="p" gutterBottom>
            Date: Sunday, July 2nd, 2023
          </Typography>
          <Typography variant="body1" component="p" gutterBottom>
            Time: 7:00 PM
          </Typography>
          <Typography variant="body1" component="p" gutterBottom>
            Location: 70 Grant, Irvine, CA, 92620
          </Typography>
          <LocationWrapper>
            <LocationIcon />
            <a
              href="https://goo.gl/maps/Ep2ZaJzYJFJaMemGA"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "white", textDecoration: "underline" }}
            >
              Google Maps
            </a>
            {"  "}|{"  "}
            <a
              href="https://maps.apple.com/?q=70+Grant,+Irvine,+CA,+92620"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "white", textDecoration: "underline" }}
            >
              Apple Maps
            </a>
          </LocationWrapper>
          <Typography variant="body1" component="p" gutterBottom>
            Dress Code: Cocktail Attire
          </Typography>
        </Event>
        <Event>
          <CircleWrapper>
            <CircleImage src={wedding2} alt="Wedding" />
          </CircleWrapper>
          <Typography variant="h3" component="h3" gutterBottom>
            Wedding
          </Typography>
          <Typography variant="body1" component="p" gutterBottom>
            Date: Tuesday, July 4th, 2023
          </Typography>
          <Typography variant="body1" component="p" gutterBottom>
            Time: 11:30 AM
          </Typography>
          <Typography variant="body1" component="p" gutterBottom>
            Location: 1600 Las Virgenes Canyon Rd, Calabasas, CA 91302
          </Typography>
          <LocationWrapper>
            <LocationIcon />
            <a
              href="https://maps.google.com/maps?q=1600+Las+Virgenes+Canyon+Rd,+Calabasas,+CA,+91302"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "white", textDecoration: "underline" }}
            >
              Google Maps
            </a>{" "}
            |{" "}
            <a
              href="https://maps.apple.com/?q=1600+Las+Virgenes+Canyon+Rd,+Calabasas,+CA,+91302"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "white", textDecoration: "underline" }}
            >
              Apple Maps
            </a>
          </LocationWrapper>
          <Typography variant="body1" component="p" gutterBottom>
            Dress Code: Indian Wedding Attire or Casuals
          </Typography>
        </Event>
      </EventsContainer>
    </EventsPage>
  );
}

export default Events;
