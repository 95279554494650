import React, { useState, useEffect } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import {
  Container,
  TextField,
  Button,
  Typography,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { styled } from "@mui/system";
import { auth, firestore } from "../../firebaseConfig";
import { updateEmail, sendPasswordResetEmail } from "firebase/auth";

const FormContainer = styled(Container)(({ theme }) => ({
  backgroundColor: "#fff",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledForm = styled("form")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "24px",
  backgroundColor: "#fff",
  borderRadius: "4px",
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: "#557054",
  fontFamily: "Zeyada, cursive",
  fontSize: "40px",
  fontWeight: "bold",
  marginBottom: "16px",
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: "16px",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: "16px",
  backgroundColor: "#557054",
  color: "white",
  "&:hover": { backgroundColor: "#2d402c" },
}));

const Profile = ({ user, firestore }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [relationship, setRelationship] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentEmail, setCurrentEmail] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");


  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleProfileUpdate = async (e) => {
    e.preventDefault();

    if (user && user.uid && firestore) {
      const userRef = doc(firestore, "userInfo", user.uid);
      await updateDoc(userRef, { firstName, lastName, relationship });
      setSnackbarMessage("Profile saved successfully");
      setOpenSnackbar(true);
    }
  };

  const handlePasswordReset = async () => {
    try {
      await sendPasswordResetEmail(auth, user.email);
      setSnackbarMessage("Request Sent, Please check your Inbox");
      setOpenSnackbar(true);
    } catch (error) {
      console.error("Error sending password reset email:", error);
      // Handle the error and display a message to the user
    }
  };

  const handleRequestEmailChange = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentEmail("");
    setNewEmail("");
  };

 const handleSubmitDialog = () => {
   // Perform validation on currentEmail and newEmail
   if (currentEmail === user.email) {
     // Request email change
     try {
       updateEmail(auth.currentUser, newEmail);
       setSnackbarMessage("Request Sent, Please check your Inbox");
       setOpenSnackbar(true);
     } catch (error) {
       console.error("Error requesting email address change:", error);
       // Handle the error and display a message to the user
     }
   } else {
     // Display an error message indicating that the current email is incorrect
     setSnackbarMessage("Current email does not match");
     setOpenSnackbar(true);
   }

   setOpenDialog(false);
   setCurrentEmail("");
   setNewEmail("");
 };


  useEffect(() => {
    const fetchProfile = async () => {
      if (user && user.uid && firestore) {
        const userRef = doc(firestore, "userInfo", user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setFirstName(userData.firstName || "");
          setLastName(userData.lastName || "");
          setRelationship(userData.relationship || "");
        }
      }
    };

    fetchProfile();
  }, [user, firestore]);

  if (!user || !user.uid || !firestore) {
    return <p>Loading...</p>; // Or some loading state
  }

  return (
    <FormContainer>
      <Container maxWidth="sm">
        <StyledForm onSubmit={handleProfileUpdate}>
          <StyledTypography variant="h5" component="h1" gutterBottom>
            Edit Profile
          </StyledTypography>
          <StyledTextField
            type="text"
            label="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          <StyledTextField
            type="text"
            label="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
          <StyledTextField
            type="text"
            label="Relationship with Host"
            value={relationship}
            onChange={(e) => setRelationship(e.target.value)}
            required
          />

          <StyledButton type="submit" variant="contained" color="primary">
            Save
          </StyledButton>
          <StyledButton
            onClick={handlePasswordReset}
            variant="contained"
            color="secondary"
          >
            Request Password Reset
          </StyledButton>
          <StyledButton
            onClick={handleRequestEmailChange}
            variant="contained"
            color="primary"
          >
            Request Email Address Change
          </StyledButton>
        </StyledForm>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity="success">
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>Request Email Address Change</DialogTitle>
          <DialogContent>
            <StyledTextField
              type="text"
              label="Current Email"
              value={currentEmail}
              onChange={(e) => setCurrentEmail(e.target.value)}
              required
            />
            <StyledTextField
              type="email"
              label="New Email"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Close
            </Button>
            <Button onClick={handleSubmitDialog} color="primary" disabled={!newEmail? true: false}>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </FormContainer>
  );
};

export default Profile;
