/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Box,
  CircularProgress,
  Container,
  Snackbar,
} from "@mui/material";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { firestore } from "../../firebaseConfig";
import { RecaptchaVerifier, getAuth } from "@firebase/auth";
import { styled } from "@mui/system";
import { keyframes } from "@emotion/react";
import Alert from "@mui/material/Alert";

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: "16px",
  backgroundColor: "#557054",
  color: "white",
  "&:hover": { backgroundColor: "#2d402c" },
}));

const ScrollableContainer = styled(Box)({
  height: "100vh",
  overflow: "auto",
});

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name || !email || !message) {
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);

    try {
      const contactsCollection = collection(firestore, "contacts");

      // Create a new document in the "contacts" collection with the current datetime
      const docRef = await addDoc(contactsCollection, {
        name,
        email,
        phone,
        message,
        replied: false,
        viewed: false,
        datetime: serverTimestamp(), // Add the current datetime
      });

      // Clear the form after successful submission
      setName("");
      setEmail("");
      setPhone("");
      setMessage("");

      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error adding document: ", error);

      setSnackbarOpen(true);
    }

    setLoading(false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        callback: function (response) {
          // reCAPTCHA solved - allow signInWithPhoneNumber.
          setRecaptchaLoaded(true);
        },
        "expired-callback": function () {
          // reCAPTCHA expired - reset the reCAPTCHA.
          setRecaptchaLoaded(false);
        },
      },
      getAuth()
    );

    window.recaptchaVerifier.render().then(function (widgetId) {
      window.recaptchaWidgetId = widgetId;
    });
  }, []);

  const spin = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  `;

  const Spinning = styled("div")`
    animation: ${spin} 2s linear infinite;
  `;

  return (
    <ScrollableContainer>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: 8,
          }}
        >
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <Alert onClose={handleSnackbarClose} severity="success">
              Message sent successfully!
            </Alert>
          </Snackbar>
          <form onSubmit={handleSubmit}>
            <TextField
              required
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              required
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              required
              label="Message"
              multiline
              rows={4}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              fullWidth
              sx={{ mb: 2 }}
            />
            <div id="recaptcha-container" />
            <StyledButton
              type="submit"
              variant="contained"
              disabled={!recaptchaLoaded || loading}
            >
              {loading ? (
                <Spinning>
                  <CircularProgress />
                </Spinning>
              ) : (
                "Submit"
              )}
            </StyledButton>
          </form>
        </Box>
      </Container>
    </ScrollableContainer>
  );
};

export default Contact;
